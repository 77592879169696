import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import Cookies from "js-cookie";
import { Modal, Statistic, Button } from "antd";

const { Countdown } = Statistic;

const API_ROOT = process.env.REACT_APP_API_ROOT;
Axios.defaults.withCredentials = true;
Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.put["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.patch["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.delete["X-CSRF-TOKEN"] = Cookies.get("csrftoken");

const IDLE_TIMEOUT = 1000 * 60 * 15;
const DEADLINE = 1000 * 59;
const BEAT_PERIOD = 1000 * 60 * 1.5;
const DEBOUNCE = 1000;

// const IDLE_TIMEOUT = 1000 * 2;
// const DEADLINE = 1000 * 6;
// const BEAT_PERIOD = 1000 * 1;
// const DEBOUNCE = 500;

// Component
const SessionManager = ({ maxAge }) => {
  const [idle, setIdle] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [sessionCreated, setSessionCreated] = useState(
    Cookies.get("logged-in") * 1000
  );

  // Replace Session when expired
  const refreshSession = async (url) => {
    Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
    let source = Axios.CancelToken.source();
    try {
      let res = await Axios.post(url, null, {
        cancelToken: source.token,
      });
      try {
        if (res.status >= 200 && res.status < 400) {
          let csrfToken = Cookies.get("csrftoken");
          setSessionCreated(Cookies.get("logged-in") * 1000);
          Axios.defaults.headers.post["X-CSRF-TOKEN"] = csrfToken;
          Axios.defaults.headers.put["X-CSRF-TOKEN"] = csrfToken;
          Axios.defaults.headers.patch["X-CSRF-TOKEN"] = csrfToken;
          Axios.defaults.headers.delete["X-CSRF-TOKEN"] = csrfToken;
        }
      } catch (e) {
        console.log(e);
        window.location.href = "/";
        source.cancel();
      }
    } catch (e) {
      console.log(e);
      window.location.href = "/";
      source.cancel();
    } finally {
      source.cancel();
    }
  };

  // Logout
  const doLogout = async (url) => {
    let source = Axios.CancelToken.source();
    try {
      await Axios.delete(url, {
        cancelToken: source.token,
      });
      window.location.href = "/";
    } catch (e) {
      window.location.href = "/";
      console.log(e);
      source.cancel();
    }
  };

  const handleOnActive = () => {
    setIdle(false);
  };

  const handleOnIdle = () => {
    setIdle(true);
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    debounce: DEBOUNCE,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  // Confirmation Modal with promise. Check if user is there.
  const showLogoutConfirm = async () => {
    setVisible(true);
    setDeadline(Date.now() + DEADLINE);
  };

  const handleOk = async () => {
    await refreshSession(`${API_ROOT}/refresh`);
    setVisible(false);
  };

  const handleCancel = async () => {
    await doLogout(`${API_ROOT}/signout`);
    setVisible(false);
  };

  // Track session age
  useEffect(() => {
    const checkSessionAge = () => {
      const now = new Date();
      const timeUntilExpiry = sessionCreated + maxAge - now.getTime();
      if (timeUntilExpiry < 0) {
        if (idle) {
          // Stop checking session age
          clearInterval(beat);
          // Trigger confirmation modal
          showLogoutConfirm();
        } else {
          // Get new cookie and csrftoken.
          refreshSession(`${API_ROOT}/refresh`);
        }
      }
    };
    const beat = setInterval(checkSessionAge, BEAT_PERIOD);

    //Cleanup
    return () => {
      clearInterval(beat);
    };
  }, [idle, maxAge, sessionCreated]);

  // TODO: Load current user
  // useEffect(() => {
  //   const loadCurrentUser = () => {

  //   }
  //   return () => {
  //     cleanup
  //   }
  // }, [input])

  return (
    <Modal
      visible={visible}
      title="Idle session detected. Stay Logged In?"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="logout" onClick={handleCancel}>
          Logout
        </Button>,
        <Button
          key="ok"
          type="primary"
          // loading={loading}
          onClick={handleOk}
        >
          Continue Session
        </Button>,
      ]}
    >
      <div style={{ textAlign: "center" }}>
        <p>Auto-logout in:</p>
        {visible && (
          <Countdown
            value={deadline}
            format="ss"
            onFinish={handleCancel}
            suffix="seconds"
          />
        )}
        <br />
        <p>Press 'Continue Session' to stay logged in.</p>
      </div>
    </Modal>
  );
};

export default SessionManager;
