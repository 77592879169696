import React, { useState, useEffect, createContext } from "react";
import {
  Button,
  Card,
  Space,
  Empty,
  Spin,
  Dropdown,
  Menu,
  message,
  notification,
} from "antd";
import {
  FileExcelFilled,
  FilePdfFilled,
  DownloadOutlined,
  GoogleSquareFilled,
  GoogleOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import Axios from "axios";

import DonorReportTable from "./DonorReportTable";
import EmailModal from "./EmailModal";

import { mmobPost } from "../../Api/mmobapi";

Axios.defaults.withCredentials = true;
const API_ROOT = process.env.REACT_APP_API_ROOT;
let source;

export const ReportContext = createContext(null);

const ReportPreviewCard = ({
  formValues,
  reportLink,
  reportData,
  loadingReport,
  description,
}) => {
  const [loadingReceipts, setLoadingReceipts] = useState(false);
  const [receiptsData, setReceiptsData] = useState(null);
  const [title, setTitle] = useState("Report Preview");
  const [emailModal, setEmailModal] = useState(false);
  const [receiptsList, setReceiptsList] = useState([]);

  const onCancel = () => {
    setEmailModal(false);
  };

  const openEmailModal = async () => {
    setEmailModal(true);
  };

  const openUploadModal = async () => {
    message.info("Feature, coming soon...");
    console.log("Opening Google Drive Upload modal...");
  };

  const getReceiptsList = async () => {
    const body = {
      file_token: reportData.Download.split("/").pop(),
      start_date: reportData.Dates[0].format("YYYY-MM-DD"),
      end_date: reportData.Dates[1].format("YYYY-MM-DD"),
    };
    const res = await mmobPost("/receipts/getReceiptsByUID", body);

    if (res.data) {
      setReceiptsList(res.data);
      return res.data;
    } else {
      console.log(res.error);
      message.error("Error loading receipts attachment data.");
    }
  };

  const generateReceipts = async () => {
    // Send POST request to API endpoint to create receipts
    // TODO: Factor out API calls into separate API module

    Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
    source = Axios.CancelToken.source();

    // Request body
    const body = {
      file_token: reportLink.split("downloads/")[1],
      start_date: formValues.dateRange[0],
      end_date: formValues.dateRange[1],
    };

    try {
      message.loading({
        content: "Generating receipts...",
        key: "receipts-status",
        duration: 0,
      });
      setReceiptsData(null);
      setLoadingReceipts(true);

      let response = await Axios.post(
        `${API_ROOT}/receipts/generateReceipts`,
        body,
        { cancelToken: source.token }
      );

      if (response.status >= 200 && response.status < 300) {
        const items = response.data.donors;
        items.forEach((item) => {
          for (const prop in item) {
            if (prop !== `{{${prop}}}`) {
              item[`{{${prop}}}`] = item[prop];
              delete item[prop];
            }
          }
        });

        setReceiptsData(response.data);
        message.destroy("receipts-status");
        notification["success"]({
          message: "Receipts are ready!",
          description:
            "You can now download them to your device, email them via Gmail or upload them to Google Drive.",
          duration: 5,
          top: 56,
        });
        await getReceiptsList();
      } else {
        message.destroy("receipts-status");
        notification["error"]({
          message: "There was an error generating receipts.",
          description: "HTTP 3xx",
          duration: 0,
          top: 56,
        });
      }
    } catch (e) {
      message.destroy("receipts-status");
      notification["error"]({
        message: "There was an error generating receipts.",
        description: `${e}`,
        duration: 0,
        top: 56,
      });
    } finally {
      setLoadingReceipts(false);
      source.cancel("axios: Cleaning up");
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={openUploadModal} key="1" icon={<GoogleOutlined />}>
        Upload Receipts to Google Drive
      </Menu.Item>
      <Menu.Item onClick={openEmailModal} key="2" icon={<GoogleSquareFilled />}>
        Email Receipts via Gmail
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (formValues) {
      setTitle(
        `Report Preview:  (${formValues.dateRange[0]} to ${formValues.dateRange[1]})`
      );
    }
    return () => {
      setReceiptsData(null);
    };
  }, [reportData, formValues]);

  return (
    <ReportContext.Provider value={reportData}>
      <>
        <Card
          className="card-box-shadow"
          title={title}
          extra={
            <Space direction="horizontal" size="middle">
              {!receiptsData ? (
                <Button
                  onClick={generateReceipts}
                  type="ghost"
                  loading={loadingReceipts}
                  hidden={!Boolean(reportData)}
                  icon={<FilePdfFilled />}
                >
                  Generate Receipts
                </Button>
              ) : (
                <Dropdown.Button
                  href={receiptsData.download}
                  type="default"
                  overlay={menu}
                  trigger={["click"]}
                >
                  <DownloadOutlined /> Download Receipts
                </Dropdown.Button>
              )}
              <Button
                href={reportLink}
                type="primary"
                hidden={!Boolean(reportData)}
                icon={<FileExcelFilled />}
              >
                Export to Excel
              </Button>
            </Space>
          }
          style={{
            borderRadius: 4,
            minHeight: "40vh",
            marginTop: "24px",
            marginBottom: "16px",
          }}
        >
          {/* Report Table */}
          {reportData ? (
            <DonorReportTable dataSource={reportData} loading={loadingReport} />
          ) : (
            <Spin size="large" spinning={loadingReport}>
              <Empty
                description={description}
                style={{
                  display: "inline",
                  verticalAlign: "middle",
                  color: "gray",
                }}
              />
            </Spin>
          )}
          <EmailModal
            reportData={reportData}
            receiptsData={receiptsData}
            receiptsList={receiptsList}
            visible={emailModal}
            onCancel={onCancel}
          />
        </Card>
      </>
    </ReportContext.Provider>
  );
};

export default ReportPreviewCard;
