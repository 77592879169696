import React, { useContext, useState } from "react";
import Axios from "axios";
import { Card, Button } from "antd";
import { CompanyFileContext } from "../DashboardContext";
import HTTPExceptionHandler from "../../httpExceptionHandler";

const API_ROOT = process.env.REACT_APP_API_ROOT;

const CompanyCard = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const companyFile = useContext(CompanyFileContext);
  // const item = props.item;

  const openCompanyFile = async (item) => {
    const url = `${API_ROOT}/company-files/${item.Id}/current-user`;
    let source = Axios.CancelToken.source();
    setLoading(true);
    try {
      let response = await Axios.get(url, { cancelToken: source.token });
      if (response.status === 200) {
        setLoading(false);
        companyFile.onCompanyFileSelect(item);
      }
    } catch (e) {
      console.log(e);
      HTTPExceptionHandler(e);
    } finally {
      source.cancel();
      setLoading(false);
    }
  };

  return (
    <Card
      hoverable
      title={item.Name}
      style={{ minWidth: 280, borderRadius: 4 }}
      actions={[
        <Button
          onClick={() => openCompanyFile(item)}
          type="primary"
          loading={loading}
        >
          Open
        </Button>,
      ]}
    >
      <p>Serial Number: {item.SerialNumber}</p>
      <p>Product Level: {item.ProductLevel.Name}</p>
      <p>Product Version: {item.ProductVersion}</p>
    </Card>
  );
};

export default CompanyCard;
