import React from "react";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import { Button, PageHeader, Typography } from "antd";
const { Title } = Typography;

Axios.defaults.withCredentials = true;
const API_ROOT = process.env.REACT_APP_API_ROOT;

const Authorize = () => {
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const state = query.get("state");
  var url = "";

  const getAuthURL = async () => {
    const response = await Axios.get(`${API_ROOT}/authorize`);
    const data = await response.data;
    data.state = state;
    url = `${data.MYOB_AUTH_URL}?client_id=${data.client_id}&redirect_uri=${data.redirect_uri}&response_type=code&state=${state}&scope=CompanyFile la.global`;
  };
  getAuthURL();

  const handleButtonClick = () => {
    // console.log("Button clicked...");
    window.location.href = url;
  };

  return (
    <>
      <PageHeader>
        <Title>MYOB AccountRight Plugin Authorization</Title>
        <Button onClick={handleButtonClick} type="primary">
          Authorize with MYOB
        </Button>
      </PageHeader>
    </>
  );
};

export default Authorize;
