import React, { useState, useEffect, useContext } from "react";
import { List, message, Button, Space, Typography, Card } from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import Axios from "axios";

import { CompanyFileContext } from "../DashboardContext";
import { mmobGet, mmobDelete } from "../../Api/mmobapi";

const { Text } = Typography;
const { Meta } = Card;

const EmailTemplateList = ({
  reportData,
  handleEdit,
  onSelect,
  toggleEditor,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let company = useContext(CompanyFileContext);
  let source;

  const getTemplates = async () => {
    let res = await mmobGet(
      `/email-templates/${company.data.Id}`,
      null,
      source
    );

    if (res.data) {
      res.data.forEach((template) => {
        if (template.attachments) {
          template.attachments = template.attachments.map((file) =>
            JSON.parse(file)
          );
        }
      });

      // console.log(res.data);
      setData(res.data);
    }

    if (res.error) {
      message.error("Error loading email templates");
    }
    setLoading(false);
  };

  function onEdit(item) {
    handleEdit(item);
    toggleEditor();
  }

  const deleteTemplate = async (guid) => {
    setLoading(true);
    let res = await mmobDelete(`/email-templates/${company.data.Id}/${guid}`);

    if (res.data) {
      message.success("Template deleted");
    } else {
      message.error("Failed to delete email template.");
    }
    await getTemplates();
    setLoading(false);
  };

  useEffect(() => {
    // Fetch list of email templates from DB
    setLoading(true);
    let source = Axios.CancelToken.source();

    getTemplates();
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [company.data.Id]);

  // Deselect template
  useEffect(() => {
    handleEdit(null);
  }, []);

  return (
    <Space
      direction="vertical"
      size={4}
      style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
    >
      <div style={{ margin: 8, marginBottom: -4, paddingBottom: 0 }}>
        <Button
          style={{ width: "100%" }}
          icon={<PlusCircleFilled />}
          type="ghost"
          onClick={toggleEditor}
        >
          Create New Template
        </Button>
      </div>
      <List
        grid={{
          gutter: 8,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        style={{ padding: "8px 8px 8px 8px" }}
        className="email-templates-list"
        // itemLayout="vertical"
        dataSource={data}
        loading={loading}
        rowKey={data.guid}
        renderItem={(item) => (
          <List.Item>
            <Card
              hoverable
              style={{
                minWidth: 280,
                borderRadius: 4,
              }}
              actions={[
                <DeleteOutlined
                  onClick={() => deleteTemplate(item.guid)}
                  key="delete"
                  title="Delete"
                />,
                <EditOutlined
                  onClick={() => onEdit(item)}
                  key="edit"
                  title="Edit"
                />,
                <SelectOutlined
                  onClick={() => onSelect(item)}
                  key="select"
                  title="Select Template"
                />,
              ]}
            >
              <div>
                <Meta
                  title={item.title}
                  description={
                    <>
                      <Space direction="vertical" size={4} style={{}}>
                        <br />
                        <Text type="primary">Created by</Text>
                        <Text type="secondary">{item.created_by}</Text>
                        <Text type="secondary">{item.created_at}</Text>
                        <br />
                        <Text type="primary">Last Modified</Text>
                        <Text type="secondary">{item.updated_at}</Text>
                      </Space>
                    </>
                  }
                ></Meta>
              </div>
            </Card>
          </List.Item>
        )}
      ></List>
    </Space>
  );
};

export default EmailTemplateList;
