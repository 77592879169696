import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Result, Button } from "antd";
import LandingPage from "./Pages/LandingPage";
import Dashboard from "./Pages/Dashboard";
import Authorize from "./Pages/Authorize";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/authorize" component={Authorize} />
        <Route path="/dashboard/company-files" component={Dashboard} />
        <Route path="*">
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button
                onClick={() => (window.location.href = "/")}
                type="primary"
              >
                Back Home
              </Button>
            }
          />
        </Route>
      </Switch>
    </Router>
  );
}

// function NoMatch() {
//   let location = useLocation();

//   return (
//     <div>
//       <h3>
//         No match for <code>{location.pathname}</code>
//       </h3>
//     </div>
//   );
// }

export default App;
