import React from "react";
import { Layout, Row, Col, Space, Switch } from "antd";
import UserProfileBar from "../Components/ProfileMenu";

const { Header } = Layout;

const API_ROOT = process.env.REACT_APP_API_ROOT;

const TopBar = (props) => {
  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Row className="header-content" style={{ height: 48, paddingRight: 12 }}>
        <Col style={{ height: 48 }} flex="auto"></Col>
        <Col style={{ height: 48 }} flex="180px">
          <Space style={{ height: 48 }} size={12}>
            <div className="header-controls">
              <Switch
                style={{ marginBottom: 18 }}
                checked={props.theme === "dark"}
                onChange={props.changeTheme}
                checkedChildren="Dark"
                unCheckedChildren="Light"
              />
            </div>
            <UserProfileBar url={`${API_ROOT}/current-user`} />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default TopBar;
