import React, { useContext } from "react";
import { Card, Button, Typography } from "antd";
import { AlertAreaContext } from "../DashboardContext";

const { Paragraph } = Typography;
const { Meta } = Card;

const CustomReportCard = ({ item, onSelect }) => {
  const alertArea = useContext(AlertAreaContext);

  const onClickHandler = () => {
    alertArea.removeAlert();
    onSelect(item);
  };

  return (
    <>
      <Card
        className="custom-report-card"
        hoverable
        style={{
          minWidth: 230,
          marginTop: 16,
          borderRadius: 4,
        }}
        cover={
          <img alt="excel" src={process.env.PUBLIC_URL + "/excel_cover.jpg"} />
        }
        actions={[
          <Button onClick={() => onClickHandler()} type="primary">
            Start
          </Button>,
        ]}
      >
        <Meta
          title={item.name}
          description={
            <Paragraph type="secondary" ellipsis={{ rows: 3 }}>
              {item.description}
            </Paragraph>
          }
        />
        <br />
        Created By: {item.createdBy}
        <br />
        Date Created: {item.dateCreated}
      </Card>
    </>
  );
};

export default CustomReportCard;
