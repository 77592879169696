import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as WirelessLogo } from "../logo.svg";
import { Layout, Menu } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CompanyFileContext } from "../Components/DashboardContext";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideNav = (props) => {
  const companyFile = useContext(CompanyFileContext);
  let location = useLocation();
  // console.log(location.pathname);

  return (
    <Sider
      theme={props.theme}
      collapsible
      collapsed={props.collapsed}
      onCollapse={props.onCollapse}
      breakpoint="md"
      collapsedWidth={48}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <div className="logo">
        <WirelessLogo />
      </div>
      <Menu
        inlineIndent={16}
        theme={props.theme}
        selectedKeys={[location.pathname]}
        mode="inline"
        // forceSubMenuRender="true"
      >
        <Menu.Item key="/dashboard/company-files" icon={<PieChartOutlined />}>
          Company Files
          <Link to="/dashboard/company-files" />
        </Menu.Item>
        {companyFile.data && (
          <>
            <Menu.Item
              key="/dashboard/company-files/custom-reports"
              icon={<FileOutlined />}
            >
              Custom Reports
              <Link to="/dashboard/company-files/custom-reports" />
            </Menu.Item>
            <Menu.Item key="2" icon={<DesktopOutlined />}>
              Option 2
            </Menu.Item>
            <SubMenu
              inlineIndent={-16}
              key="sub1"
              icon={<UserOutlined />}
              title="Option 3"
            >
              <Menu.Item key="3">Tom</Menu.Item>
              <Menu.Item key="4">Bill</Menu.Item>
              <Menu.Item key="5">Alex</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<TeamOutlined />} title="Option 4">
              <Menu.Item key="6">Team 1</Menu.Item>
              <Menu.Item key="8">Team 2</Menu.Item>
            </SubMenu>
          </>
        )}
      </Menu>
    </Sider>
  );
};

export default SideNav;
