import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, PageHeader, Spin } from "antd";

import SideNav from "../Components/SideNav";
import TopBar from "../Components/TopBar";
import MainContent from "../Components/MainContent";
import AlertArea from "../Components/AlertArea";
import DashboardContext from "../Components/DashboardContext";
import SessionManager from "../SessionManager";
import "./Dashboard.css";

const { Content, Footer } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [companyFile, setCompanyFile] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const location = useLocation();
  let headerTitle = location.pathname
    .split("/")
    .splice(-1)[0]
    .replace("-", " ")
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");

  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const promptCFCredentials = (companyFile) => {
    setCompanyFile(companyFile);
  };

  const onCompanyFileSelect = (companyFile) => {
    // setCompanyFile(companyFile);
    promptCFCredentials(companyFile);
  };

  const removeAlert = () => {
    setAlerts(alerts.splice(alerts.length - 1, alerts.length));
  };

  const clearAllAlerts = () => {
    setAlerts(alerts.splice(0, alerts.length));
  };

  const addAlert = (alert) => {
    // setAlerts((alerts) => [...alerts, alert]);
    setAlerts(alert);
  };

  const currentUser = {
    user: null,
    id: null,
  };
  const companyFileValues = {
    data: companyFile,
    onCompanyFileSelect: onCompanyFileSelect,
  };
  const alertValues = {
    alerts: alerts,
    addAlert: addAlert,
    removeAlert: removeAlert,
    clearAllAlerts: clearAllAlerts,
  };

  let routes = [
    {
      path: "/dashboard/company-files",
      breadcrumbName: "Company Files",
    },
    {
      breadcrumbName: companyFile ? companyFile.Name : null,
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <DashboardContext
        alertValues={alertValues}
        companyFileValues={companyFileValues}
        currentUser={currentUser}
      >
        <SideNav onCollapse={onCollapse} collapsed={collapsed} theme={theme} />
        <Layout className="site-layout">
          <TopBar theme={theme} changeTheme={changeTheme} />
          <PageHeader
            className="site-page-header"
            title={headerTitle}
            breadcrumb={{ routes }}
            ghost={true}
          />
          <Content style={{ margin: "24px 24px" }}>
            <AlertArea alerts={alerts} />
            <Spin spinning={!currentUser}>
              {!!currentUser && <MainContent />}
            </Spin>
          </Content>
          <Footer style={{ textAlign: "center", color: "gray" }}>
            John Zambrano © 2020 | Built with Ant Design
          </Footer>
        </Layout>
      </DashboardContext>
      <SessionManager maxAge={1000 * 60 * 50} />
    </Layout>
  );
};

export default Dashboard;
