import React, { useState, useEffect } from "react";
import { Alert, Typography, Space, Slider, InputNumber, Row, Col } from "antd";

const { Title, Text, Paragraph } = Typography;

const ReviewDraftEmail = ({
  reportData,
  receiptsData,
  receiptsList,
  template,
}) => {
  const [sliderInput, setSliderInput] = useState(1);
  const [currentDraft, setCurrentDraft] = useState(template);

  const onChange = (value) => {
    setSliderInput(value);
    const replacements = receiptsData.donors[value - 1];

    fillPlaceholders(replacements);
  };

  const fillPlaceholders = (replacements) => {
    const newRecipients = template.recipients.map((recipient) =>
      recipient.replace(/{{([^}]*)}}/g, function (all) {
        return replacements[all] || all;
      })
    );
    const newSubject = template.subject.replace(/{{([^}]*)}}/g, function (all) {
      return replacements[all] || all;
    });
    const newContent = template.content.replace(/{{([^}]*)}}/g, function (all) {
      return replacements[all] || all;
    });

    let newAttachments = [];
    if (receiptsList) {
      const donorID = replacements["{{Contact.UID}}"];
      newAttachments = [...template.attachments.map((attach) => attach.name)];
      if (receiptsList[donorID]) {
        newAttachments.push(...receiptsList[donorID]);
      }
    }

    setCurrentDraft({
      ...template,
      subject: newSubject,
      content: JSON.parse(newContent)
        .blocks.map((block) => block.text)
        .join("\n"),
      recipients: newRecipients,
      attachments: newAttachments,
    });
  };

  useEffect(() => {
    const replacements = receiptsData.donors[sliderInput];
    fillPlaceholders(replacements);
    //
  }, []);

  return (
    <Space
      direction="vertical"
      align="center"
      style={{ height: "100%", width: "100%", padding: 16 }}
    >
      <Alert
        style={{ marginBottom: 8, width: "100%" }}
        message="Test and review your email template on your report data without having to send any emails."
        type="info"
        closable
        // closeText="Continue"
        showIcon
      />
      <Space
        direction="horizontal"
        align="end"
        style={{ width: "100%", marginTop: 8, marginBottom: 8 }}
      >
        <Row style={{ width: "50vw" }} justify="center">
          <Col span={10}>
            <Slider
              min={1}
              max={receiptsData ? receiptsData.donors.length : 1}
              onChange={onChange}
              value={typeof sliderInput === "number" ? sliderInput : 1}
              step={1}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={1}
              max={receiptsData ? receiptsData.donors.length : 1}
              style={{ margin: "0 16px" }}
              value={sliderInput}
              onChange={onChange}
            />
          </Col>
        </Row>
      </Space>
      <div style={{ margin: "8px" }}>
        <Title level={3}>
          {template.title} <Text type="secondary">&nbsp;Draft</Text>
        </Title>
        <Title level={5}>Recipients:</Title>
        <Text>
          <pre>{currentDraft.recipients.join(", ")}</pre>
        </Text>
        <Title level={5}>Subject:</Title>
        <Text>
          <pre>{currentDraft.subject}</pre>
        </Text>
        <Title level={5}>Body:</Title>
        <Paragraph>
          <pre>{currentDraft.content}</pre>
        </Paragraph>
        <Title level={5}>Attachments:</Title>
        <Text>
          <pre>{currentDraft.attachments.join("\n")}</pre>
        </Text>
      </div>
    </Space>
  );
};

export default ReviewDraftEmail;
