import React from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import { Avatar, Typography, Space, Skeleton } from "antd";
import { Menu, Dropdown, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import withFetch from "../withFetch";
import "./ProfileMenu.css";
const { Text } = Typography;

Axios.defaults.withCredentials = true;
Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.put["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.patch["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
Axios.defaults.headers.delete["X-CSRF-TOKEN"] = Cookies.get("csrftoken");

const API_ROOT = process.env.REACT_APP_API_ROOT;

const UserProfile = (props) => {
  let data = null;

  try {
    data = props.dataSource.username;
  } catch (err) {
    data = null;
  }

  const handleLogout = async (url) => {
    let source = Axios.CancelToken.source();
    try {
      const res = await Axios.delete(url, {
        cancelToken: source.token,
      });
      try {
        if (res.status === 200 || res.status === 204) {
          window.location.href = "/";
        }
      } catch (e) {
        window.location.href = "/";
        console.log(e);
      } finally {
        source.cancel();
      }
    } catch (e) {
      window.location.href = "/";
      console.log(e);
      source.cancel();
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleLogout(`${API_ROOT}/signout`)}>
        <Text>Logout</Text>
      </Menu.Item>
      <Menu.Item
        onClick={() => handleLogout(`${API_ROOT}/signout-all-devices`)}
      >
        <Text>Logout (All devices)</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {!data ? (
        <div
          style={{ position: "relative", width: 214, height: 48, flex: true }}
        >
          <Skeleton.Button
            active
            size="small"
            shape="square"
            style={{ width: 150, marginTop: 12 }}
          />
          <Skeleton.Avatar active shape="circle" style={{ margin: 8 }} />
        </div>
      ) : (
        <Dropdown
          id="user-profile-dropdown"
          overlay={menu}
          trigger="hover"
          placement="bottomRight"
        >
          <Button id="user-profile-button" type="link" size="small">
            <Space size={16}>
              <Text>{data}</Text>
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={<UserOutlined />}
              />
            </Space>
          </Button>
        </Dropdown>
      )}
    </>
  );
};

const UserProfileBar = withFetch(UserProfile);

export default UserProfileBar;
