import React, { useContext, useEffect } from "react";
import { v4 as uuid4 } from "uuid";
import { Typography, PageHeader, Button, List, Alert } from "antd";
import {
  CompanyFileContext,
  AlertAreaContext,
} from "../Components/DashboardContext";

const { Paragraph } = Typography;

const CompanyFileHome = () => {
  const companyFile = useContext(CompanyFileContext);
  const alertContext = useContext(AlertAreaContext);

  const switchCompanyFile = () => {
    companyFile.onCompanyFileSelect(null);
  };

  useEffect(() => {
    alertContext.addAlert([
      <Alert
        style={{ marginBottom: "16px" }}
        message="You can perform operations on the selected company file via the left sidebar. If you wish to select a different company file, click the 'Switch Company File' button."
        type="success"
        closable
        showIcon
        key={uuid4()}
      />,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="site-layout-background main-content-bg"
        style={{ marginTop: 16, padding: 24, minHeight: "50vh" }}
      >
        <PageHeader
          title={companyFile.data.Name}
          subTitle="Currently loaded"
          extra={[
            <Button
              onClick={switchCompanyFile}
              key="switch_company_file"
              type="primary"
            >
              Switch Company File
            </Button>,
          ]}
        >
          <List>
            <Paragraph style={{ maxWidth: 800 }}></Paragraph>
            <List.Item>
              <strong>Serial: </strong>
              {companyFile.data.SerialNumber}
            </List.Item>
            <List.Item>
              <strong>Id: </strong>
              {companyFile.data.Id}
            </List.Item>
            <List.Item>
              <strong>Product Version: </strong>
              {companyFile.data.ProductVersion}
            </List.Item>
            <List.Item>
              <strong>Checked-out Date: </strong>
              {companyFile.data.CheckedOutDate}
            </List.Item>
            <List.Item>
              <strong>Checked-out By: </strong>
              {companyFile.data.CheckedOutBy}
            </List.Item>
          </List>
        </PageHeader>
      </div>
    </>
  );
};

export default CompanyFileHome;
