import React from "react";
import { Table } from "antd";

const DonorReportTable = ({ dataSource, loading }) => {
  // Get column headers from json object
  const keys = Object.keys(dataSource[0]);
  const columns = keys
    .map((key) => ({
      title: key,
      dataIndex: key,
      key: key,
    }))
    .filter(
      (col) =>
        !col.key.includes("URI") &&
        !col.key.includes("UID") &&
        !col.key.includes("RowVersion") &&
        !col.key.includes("Date")
    );

  // Format Date Column
  if ("Date" in dataSource[0]) {
    dataSource.forEach((item) => {
      const d = new Date(item.Date);
      item.Date = d.toDateString();
    });
    columns.unshift({
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
    });
  }

  return (
    <>
      <Table
        dataSource={dataSource}
        rowKey="UID"
        loading={loading}
        scroll={{ x: 9500, y: "50vh" }}
        columns={columns}
        pagination={{
          position: ["bottomRight"],
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
        }}
        footer={() => `Total Records: ${dataSource.length}`}
      />
    </>
  );
};

export default DonorReportTable;
