import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import CustomReports from "../Pages/CustomReports";
import CompanyFileHome from "../Pages/CompanyFileHome";
import CompanyFilesList from "./Company/CompanyCardList";
// import CreateCustomReportDialog from "./CreateCustomReportDialog";
import GenerateReportDialog from "./Reports/GenerateReportDialog";
import { CompanyFileContext } from "../Components/DashboardContext";
import "../Pages/Dashboard.css";

const API_ROOT = process.env.REACT_APP_API_ROOT;

const MainContent = () => {
  const companyFile = useContext(CompanyFileContext);

  // Render this if a Company File has been selected...
  if (companyFile.data) {
    return (
      <>
        <Switch>
          <Route path="/dashboard/company-files/custom-reports/:report">
            <GenerateReportDialog />
          </Route>
          <Route path="/dashboard/company-files/custom-reports">
            <CustomReports companyFile={companyFile} />
          </Route>
          <Route exact path="/dashboard/company-files">
            <CompanyFileHome />
          </Route>
        </Switch>
      </>
    );

    // Otherwise get user to select Company File
  } else {
    return (
      <Switch>
        <Route exact path="/dashboard/company-files">
          <CompanyFilesList url={`${API_ROOT}/company-files/`} />
        </Route>
      </Switch>
    );
  }
};

export default MainContent;
