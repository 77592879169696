import React from "react";

const AlertArea = ({ alerts }) => {
  const max_visible_alerts = 3;

  return (
    <div id="alert-area" style={{ padding: "0 0 1px", marginBottom: "-6px" }}>
      {alerts.slice(
        Math.max(alerts.length - max_visible_alerts, 0),
        alerts.length
      )}
    </div>
  );
};

export default AlertArea;
