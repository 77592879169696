import React from "react";
import { Modal } from "antd";
import EmailSteps from "./EmailSteps";

const EmailModal = ({
  reportData,
  receiptsData,
  receiptsList,
  visible,
  onCancel,
}) => {
  return (
    <Modal
      width={1000}
      title="Email Receipts via Gmail"
      visible={visible}
      // confirmLoading={confirmLoading}
      footer={null}
      onCancel={onCancel}
    >
      <EmailSteps
        reportData={reportData}
        receiptsData={receiptsData}
        receiptsList={receiptsList}
      />
    </Modal>
  );
};

export default EmailModal;
