import Axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

Axios.defaults.withCredentials = true;
let source;

const withFetch = (WrappedComponent) => {
  const Fetcher = (props) => {
    const [data, setData] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      source = Axios.CancelToken.source();

      const loadData = async () => {
        try {
          const response = await Axios.get(props.url, {
            cancelToken: source.token,
          });
          setIsAuthorized(true);
          // ReactJS requires list elements to have unique key property
          if (Array.isArray(response.data)) {
            response.data.map((row) => (row.key = row.Id));
          }
          setData(response.data);
          setLoading(false);
        } catch (err) {
          if ("response" in err) {
            if (err.response) {
              setData(err.response.data);
            } else {
              setData(null);
              setLoading(false);
            }
          } else if ("request" in err) {
            console.log("Request error");
          } else if (Axios.isCancel(err)) {
            console.log("Request cancelled.");
          } else {
            console.log(err);
          }
        }
      };
      loadData();

      return () => {
        source.cancel();
      };
    }, [props.url]);

    if (!data) {
      return (
        <WrappedComponent {...props} dataSource={data} loading={loading} />
      );
    } else if (!isAuthorized) {
      return <Redirect to={`${data}`} />;
    } else {
      return (
        <WrappedComponent {...props} dataSource={data} loading={loading} />
      );
    }
  };
  return Fetcher;
};

export default withFetch;
