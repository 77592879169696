// TODO: Refactor axios requests and settings CSRF Headers
// TODO: Refactor 401, 403, 500 exceptions
import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Form, DatePicker, Button, Card, Space } from "antd";

import Axios from "axios";
import { CompanyFileContext } from "../DashboardContext";
import HTTPExceptionHandler from "../../httpExceptionHandler";

import ReportPreviewCard from "./ReportPreviewCard";

const { RangePicker } = DatePicker;

Axios.defaults.withCredentials = true;
const API_ROOT = process.env.REACT_APP_API_ROOT;
let source;

// Form config
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 10 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const rangeConfig = {
	rules: [
		{
			type: "array",
			required: true,
			message: "Please select dates",
		},
	],
};

//
// Component
const GenerateReportDialog = () => {
	const [loadingReport, setLoadingReport] = useState(false);
	const [reportData, setReportData] = useState(null);
	const [reportLink, setReportLink] = useState(null);
	const [description, setDescription] = useState("No Data");
	const [formValues, setFormValues] = useState(null);
	const companyFile = useContext(CompanyFileContext);
	let history = useHistory();

	const onCancel = () => {
		if (source) {
			source.cancel();
		}
		history.goBack();
	};

	const onSubmit = async (fieldsValue) => {
		Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
		// Create new axios cancel token
		source = Axios.CancelToken.source();
		// Reset data
		setReportData(null);
		// Should format date value before submit.
		const rangeValue = fieldsValue["dateRange"];
		const values = {
			...fieldsValue,
			dateRange: [
				rangeValue[0].format("YYYY-MM-DD"),
				rangeValue[1].format("YYYY-MM-DD"),
			],
		};

		setFormValues(values);

		const body = {
			base_report: "Banking/ReceiveMoneyTxn",
			other_reports: ["Contact/Customer"],
			join_on: "Contact.UID",
		};

		// Query params
		const params = {
			expand: "Lines",
			export: "xlsx",
			top: 1000,
			skip: 0,
			render: "json_flat",
			filter: `Date ge datetime'${values.dateRange[0]}' and Date le datetime'${values.dateRange[1]}' and (Account/DisplayID eq '1-1131' or Account/DisplayID eq '1-1132' or Account/DisplayID eq '1-1133' or Account/DisplayID eq '1-1134') and Lines/any(line: line/Account/DisplayID eq '4-0530')`,
		};

		// Send POST Request
		try {
			setLoadingReport(true);
			setDescription("Loading...");
			let response = await Axios.post(
				`${API_ROOT}/company-files/${companyFile.data.Id}/custom-reports`,
				body,
				{ params: params, cancelToken: source.token }
			);
			if (response.status >= 200 && response.status < 300) {
				setLoadingReport(false);
				response.data.Items.Dates = rangeValue;
				response.data.Items.Download = response.data.download;
				setReportData(response.data.Items);
				setReportLink(response.data.download);
			} else {
				setLoadingReport(false);
				console.log("Response 3xx received: ", response);
			}
		} catch (e) {
			setLoadingReport(false);
			setReportData(null);
			if (!e.response) {
				setDescription("No Data");
			} else if (e.response.status === 404) {
				setDescription("Error downloading data. Please try again.");
			} else {
				HTTPExceptionHandler(e);
			}
			// source.cancel("axios: Cleaning up");
		} finally {
			source.cancel("axios: Cleaning up");
		}
	};

	return (
		<>
			<Card
				className="card-box-shadow"
				title="Donor Report Generator"
				style={{
					borderRadius: 4,
					marginTop: "16px",
					marginBottom: "16px",
				}}
			>
				<div className="form-wrapper">
					<Form
						name="generate_donor_report"
						{...formItemLayout}
						onFinish={onSubmit}
					>
						<Form.Item
							name="dateRange"
							label="Date range (inclusive)"
							{...rangeConfig}
						>
							<RangePicker />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								xs: { span: 24, offset: 0 },
								sm: { span: 16, offset: 10 },
							}}
						>
							<Space>
								<Button
									style={{ marginTop: "4px" }}
									type="primary"
									htmlType="submit"
									loading={loadingReport}
								>
									Generate Report
								</Button>
								<Button
									style={{ marginTop: "4px" }}
									type="ghost"
									onClick={() => onCancel()}
								>
									Cancel
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</div>
			</Card>

			{/* Show preview of data as a table */}

			<ReportPreviewCard
				formValues={formValues}
				reportLink={reportLink}
				reportData={reportData}
				loadingReport={loadingReport}
				description={description}
			/>
		</>
	);
};

export default GenerateReportDialog;
