import React, { useState, useEffect, useContext } from "react";
import { Steps, Button, message, notification } from "antd";
import moment from "moment";
import "./CreateCustomReportDialog.css";
import ConnectGmailDialog from "./ConnectGmailDialog";
import EmailEditor from "./EmailEditor";
import EmailTemplateList from "./EmailTemplateList";
import ReviewDraftEmail from "./ReviewDraftEmail";
import SendReceipts from "./SendReceipts";
import { mmobPost } from "../../Api/mmobapi";
import { CompanyFileContext } from "../DashboardContext";
const { Step } = Steps;

const EmailSteps = ({ reportData, receiptsData, receiptsList }) => {
  const [googleUser, setGoogleUser] = useState(null);
  const [current, setCurrent] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [draftResults, setDraftResults] = useState(null);
  const [sending, setSending] = useState(false);
  const company = useContext(CompanyFileContext);
  const [excludeDate, setExcludeDate] = useState(null);

  const selectGoogleUser = (username) => {
    setGoogleUser(username);
  };

  const handleDisabled = (disabled) => {
    setDisabled(disabled);
  };

  const handleEdit = (template) => {
    setCurrentTemplate(template);
  };

  const handleTemplate = (template) => {
    setCurrentTemplate(template);
    next();
  };

  const handleDraftResults = (results) => {
    setDraftResults(results);
  };

  const handleExcludeDate = (datetime) => {
    setExcludeDate(datetime);
  };

  const handleSendEmails = async () => {
    setSending(true);
    message.loading({
      content: "Sending emails in background...",
      key: "email-status",
      duration: 0,
    });
    if (draftResults) {
      // Drafts already exist. Send those. Use special endpoint
      const report = {
        file_token: reportData.Download.split("downloads/")[1],
        start_date: reportData.Dates[0].format("YYYY-MM-DD"),
        end_date: reportData.Dates[1].format("YYYY-MM-DD"),
        receipts_dir: receiptsData.filename,
        last_emailed: excludeDate,
        current_time: moment().format(),
      };
      const body = { drafts: draftResults, report: report };

      const res = await mmobPost(
        `/receipts/${company.data.Id}/send-drafts`,
        body
      );
      if (res.data.length) {
        const sent = res.data.filter((r) => !r.error).length;
        if (sent !== res.data.length) {
          notification["warning"]({
            message: `${sent} / ${res.data.length} emails sent`,
            description:
              "Some emails were not sent successfully. Check your Gmail 'Sent' folder to review them.",
            duration: 0,
            top: 56,
          });
        } else {
          notification["success"]({
            message: `${sent} / ${res.data.length} emails sent`,
            description: "Check your Gmail 'Sent' folder to view sent emails.",
            duration: 0,
            top: 56,
          });
        }
      }

      if (res.error) {
        console.log("Send emails error: ", res.error);
      }
      message.destroy("email-status");
    } else {
      // Create email messages and send
      // Request body
      const body = {
        file_token: reportData.Download.split("downloads/")[1],
        start_date: reportData.Dates[0].format("YYYY-MM-DD"),
        end_date: reportData.Dates[1].format("YYYY-MM-DD"),
        receipts_dir: receiptsData.filename,
        last_emailed: excludeDate,
        current_time: moment().format(),
      };
      // Request params
      const params = {
        template_id: currentTemplate.guid,
        draft: false,
      };

      const res = await mmobPost(
        `/receipts/${company.data.Id}/send`,
        body,
        params
      );

      if (res.data.results.length) {
        const sent = res.data.results.filter((res) => res).length;
        if (sent !== res.data.results.length) {
          notification["warning"]({
            message: `${sent} / ${res.data.results.length} emails sent`,
            description:
              "Some emails were not sent successfully. Check your Gmail 'Sent' folder to review them.",
            duration: 0,
            top: 56,
          });
        } else {
          notification["success"]({
            message: `${sent} / ${res.data.results.length} emails sent`,
            description: "Check your Gmail 'Sent' folder to view sent emails.",
            duration: 0,
            top: 56,
          });
        }
      }

      if (res.error) {
        console.log("Send emails error: ", res.error);
      }

      message.destroy("email-status");
    }
    setSending(false);
  };

  let steps = [
    {
      title: "Connect Gmail Account",
      content: (
        <ConnectGmailDialog
          googleUser={googleUser}
          userCallback={selectGoogleUser}
        />
      ),
      description: "",
    },
    {
      title: "Select E-mail Template",
      content: (
        <EmailTemplatesMenu
          reportData={reportData}
          receiptsData={receiptsData}
          receiptsList={receiptsList}
          handleDisabled={handleDisabled}
          handleTemplate={handleTemplate}
          handleEdit={handleEdit}
          currentTemplate={currentTemplate}
        />
      ),
      description: "",
    },
    {
      title: "Review Email Template",
      content: (
        <ReviewDraftEmail
          reportData={reportData}
          receiptsData={receiptsData}
          receiptsList={receiptsList}
          template={currentTemplate}
        />
      ),
      description: "",
    },
    {
      title: "Send Emails",
      content: (
        <SendReceipts
          receiptsData={receiptsData}
          reportData={reportData}
          template={currentTemplate}
          handleDraftResults={handleDraftResults}
          draftResults={draftResults}
          handleExcludeDate={handleExcludeDate}
        />
      ),
      description: "",
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (!googleUser) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [googleUser]);

  return (
    <div className="form-steps-dialog">
      <Steps current={current} style={{ paddingBottom: "24px" }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            disabled={disabled || !googleUser}
            type="primary"
            onClick={() => next()}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button loading={sending} type="primary" onClick={handleSendEmails}>
            Send Emails
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

const EmailTemplatesMenu = ({
  reportData,
  receiptsData,
  handleDisabled,
  handleEdit,
  handleTemplate,
  currentTemplate,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  // const [currentTemplate, setCurrentTemplate] = useState(null);

  function toggleEditor() {
    setShowEditor(!showEditor);
  }

  useEffect(() => {
    handleDisabled(true);
    return () => {
      handleDisabled(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showEditor ? (
        <EmailEditor
          reportData={reportData}
          receiptsData={receiptsData}
          onClose={handleEdit}
          template={currentTemplate}
          toggleEditor={toggleEditor}
        />
      ) : (
        <EmailTemplateList
          reportData={reportData}
          receiptsData={receiptsData}
          handleEdit={handleEdit}
          onSelect={handleTemplate}
          toggleEditor={toggleEditor}
        />
      )}
    </>
  );
};

export default EmailSteps;
