import Axios from "axios";
import Cookies from "js-cookie";

Axios.defaults.withCredentials = true;
// Axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (typeof error.response === "undefined") {
//       alert(
//         "A network error occurred. " +
//           "This could be a CORS issue or a dropped internet connection. " +
//           "Or the server is down."
//       );
//     }
//     return Promise.reject(error);
//   }
// );
const API_ROOT = process.env.REACT_APP_API_ROOT;

const mmobGet = async (
  urlPath,
  params = {},
  source = Axios.CancelToken.source()
) => {
  let options = { cancelToken: source.token, params: params };
  let result = { data: null, error: null, status: null };

  try {
    let response = await Axios.get(API_ROOT + urlPath, options);
    result.data = response.data;
    result.status = response.status;
  } catch (e) {
    // console.log("Axios error:", e);
    result.error = e;
  } finally {
    source.cancel("axios: Cleaning up");
    return result;
  }
};

const mmobPost = async (
  urlPath,
  body = null,
  params = {},
  options = {},
  source = Axios.CancelToken.source()
) => {
  Axios.defaults.headers.post["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
  let all_options = { ...options, cancelToken: source.token, params: params };
  let result = { data: null, error: null, status: null };

  try {
    let response = await Axios.post(API_ROOT + urlPath, body, all_options);
    result.data = response.data;
    result.status = response.status;
  } catch (e) {
    if ("response" in e) {
      result.status = e.response.status;
      result.error = e;
      result.error.detail = e.response.data.detail;
    } else {
      result.error = e;
    }
  } finally {
    source.cancel("axios: Cleaning up");
    return result;
  }
};

const mmobPut = async (
  urlPath,
  body = null,
  params = {},
  source = Axios.CancelToken.source()
) => {
  Axios.defaults.headers.put["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
  let options = { cancelToken: source.token, params: params };
  let result = { data: null, error: null, status: null };

  try {
    let response = await Axios.put(API_ROOT + urlPath, body, options);
    result.data = response.data;
    result.status = response.status;
  } catch (e) {
    // console.log("Axios error:", e);
    result.error = e;
  } finally {
    source.cancel("axios: Cleaning up");
    return result;
  }
};

const mmobDelete = async (
  urlPath,
  params = {},
  options = {},
  source = Axios.CancelToken.source()
) => {
  Axios.defaults.headers.delete["X-CSRF-TOKEN"] = Cookies.get("csrftoken");
  let all_options = { ...options, cancelToken: source.token, params: params };
  let result = { data: null, error: null, status: null };

  try {
    let response = await Axios.delete(API_ROOT + urlPath, all_options);
    result.data = response.data;
    result.status = response.status;
  } catch (e) {
    // console.log("Axios error:", e);
    result.error = e;
  } finally {
    source.cancel("axios: Cleaning up");
    return result;
  }
};

export { mmobGet, mmobPost, mmobPut, mmobDelete };
