import React, { createContext } from "react";

export const CompanyFileContext = createContext(null);
export const AlertAreaContext = createContext(null);
export const CurrentUserContext = createContext(null);

const DashboardContext = (props) => {
  return (
    <CompanyFileContext.Provider value={props.companyFileValues}>
      <CurrentUserContext.Provider value={props.currentUser}>
        <AlertAreaContext.Provider value={props.alertValues}>
          {props.children}
        </AlertAreaContext.Provider>
      </CurrentUserContext.Provider>
    </CompanyFileContext.Provider>
  );
};

export default DashboardContext;
