import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid4 } from "uuid";
import { Alert } from "antd";
import { AlertAreaContext } from "../Components/DashboardContext";
import CustomReportCardList from "../Components/Reports/CustomReportCardList";

// const API_ROOT = process.env.REACT_APP_API_ROOT;

// Mock data
const customReports = [
  {
    name: "Donor Report",
    dateCreated: "01/01/2020",
    createdBy: "John Zambrano",
    description:
      "Generate an Excel report showing AEPL Donations within a specified date interval, grouped by donor and Building Fund.",
    id: uuid4(),
  },
  {
    name: "Custom Report 2",
    dateCreated: "01/01/2020",
    createdBy: "John Zambrano",
    description:
      "Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum posuere urna nec tincidunt praesent semper feugiat nibh",
    id: uuid4(),
  },
  {
    name: "Custom Report 3",
    dateCreated: "01/01/2020",
    createdBy: "John Zambrano",
    description:
      "Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum posuere urna nec tincidunt praesent semper feugiat nibh",
    id: uuid4(),
  },
  {
    name: "Custom Report 4",
    dateCreated: "01/01/2020",
    createdBy: "John Zambrano",
    description:
      "Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum posuere urna nec tincidunt praesent semper feugiat nibh",
    id: uuid4(),
  },
  {
    name: "Custom Report 5",
    dateCreated: "01/01/2020",
    createdBy: "John Zambrano",
    description:
      "Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum posuere urna nec tincidunt praesent semper feugiat nibh",
    id: uuid4(),
  },
];

// Component
const CustomReports = ({ companyFile }) => {
  const alertContext = useContext(AlertAreaContext);
  let history = useHistory();
  let location = useLocation();

  const openGenReportDialog = (item) => {
    const path = location.pathname.split("/custom-reports");
    const fullpath = path[0] + "/custom-reports";
    history.push(fullpath + `/${encodeURIComponent(item.name)}`);
  };

  useEffect(() => {
    alertContext.addAlert([
      <Alert
        style={{ marginBottom: "16px" }}
        message="Custom Reports: Generate a custom report or create a new custom report generator."
        type="info"
        closable
        showIcon
        key={uuid4()}
      />,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomReportCardList data={customReports} onSelect={openGenReportDialog} />
  );
};

export default CustomReports;
