import React, { useState } from "react";
import BraftEditor from "braft-editor";
import { Dropdown, Select } from "antd";
import { ContentUtils } from "braft-utils";
import "braft-editor/dist/index.css";
import "./TextEditor.css";

const { Option } = Select;

export const VariableSearchInput = ({
  handleInsert,
  reportData,
  receiptsData,
}) => {
  const [value, setValue] = useState(null);

  const columns = Object.keys(receiptsData.donors[0]);
  const options = columns.map((item) => <Option key={item}>{item}</Option>);

  // options.push(
  //   <Option key="ReportStartDate" value="ReportStartDate">
  //     ReportStartDate
  //   </Option>,
  //   <Option key="ReportEndDate" value="ReportEndDate">
  //     ReportEndDate
  //   </Option>
  // );

  const handleSelect = (value) => {
    setValue(value);
    handleInsert(value);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 4,
        boxShadow: "0 1px 10px rgba(0, 21, 41, 0.30)",
      }}
    >
      <Select
        onSelect={handleSelect}
        autoClearSearchValue={true}
        allowClear={true}
        style={{ width: "200px", margin: 2 }}
        showSearch
        value={value}
        placeholder="Insert variable"
        defaultActiveFirstOption={true}
        showArrow={true}
        filterOption={true}
        // onChange={handleChange}
        notFoundContent={null}
        // getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        {options}
      </Select>
    </div>
  );
};

const TextEditor = ({
  reportData,
  receiptsData,
  template,
  updateContent,
  handleSave,
}) => {
  //   Set the initial content of the editor
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(template ? template.content : "")
  );

  let extendControls = [
    "bold",
    "italic",
    "underline",
    "text-color",
    "separator",
    "link",
  ];

  const handleInsert = (variable) => {
    setEditorState(ContentUtils.insertText(editorState, variable));
  };

  // Edit content trigger
  const handleChange = (editorState) => {
    setEditorState(editorState);
    updateContent(editorState.toRAW());
  };

  const handleUpdateSave = (editorState) => {
    updateContent(editorState.toRAW());
    handleSave();
  };

  return (
    <>
      <Dropdown
        overlay={
          <VariableSearchInput
            handleInsert={handleInsert}
            reportData={reportData}
            receiptsData={receiptsData}
          />
        }
        trigger={["contextMenu"]}
        // getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        <div>
          <BraftEditor
            contentStyle={{ height: 330 }}
            value={editorState}
            extendControls={extendControls}
            onChange={handleChange}
            language="en"
            onSave={() => handleUpdateSave(editorState)}
          />
        </div>
      </Dropdown>
    </>
  );
};

export default TextEditor;
