import React, { useContext, useState } from "react";
import moment from "moment";
import {
  message,
  notification,
  Button,
  Alert,
  Space,
  Typography,
  DatePicker,
} from "antd";
import { mmobDelete, mmobPost } from "../../Api/mmobapi";

import { CompanyFileContext } from "../DashboardContext";

const { Text } = Typography;

const SendReceipts = ({
  receiptsData,
  reportData,
  template,
  handleDraftResults,
  draftResults,
  handleExcludeDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [excludeDate, setExcludeDate] = useState(null);
  let company = useContext(CompanyFileContext);

  const sendDrafts = async () => {
    setLoading(true);
    message.loading({
      content: "Creating drafts in background...",
      key: "email-status",
      duration: 0,
    });

    // Request body
    const body = {
      file_token: reportData.Download.split("downloads/")[1],
      start_date: reportData.Dates[0].format("YYYY-MM-DD"),
      end_date: reportData.Dates[1].format("YYYY-MM-DD"),
      receipts_dir: receiptsData.filename,
      last_emailed: excludeDate,
      current_time: moment().format(),
    };
    // Request params
    const params = {
      template_id: template.guid,
    };

    // Hit emailReceipts endpoint
    const res = await mmobPost(
      `/receipts/${company.data.Id}/send`,
      body,
      params
    );

    if (res.data) {
      handleDraftResults(res.data.results);
      const sent = res.data.results.filter((res) => res).length;
      if (sent && sent !== res.data.results.length) {
        notification["warning"]({
          message: `${sent} / ${res.data.results.length} emails sent`,
          description:
            "Some draft emails weren't created. Check your Gmail 'Drafts' folder to review them.",
          duration: 0,
          top: 56,
        });
      } else if (!sent) {
        notification["error"]({
          message: `${sent} / ${res.data.results.length} draft emails created`,
          description: "Error creating draft emails.",
          duration: 0,
          top: 56,
        });
      } else {
        notification["success"]({
          message: `${sent} / ${res.data.results.length} Draft emails created`,
          description:
            "Check your Gmail Drafts folder to review the drafts. If you're happy with the drafts, come back here and click 'Send Emails' to finally send the emails.",
          duration: 0,
          top: 56,
        });
      }
    } else {
      notification["error"]({
        message: `Error creating Gmail Drafts: ${res.error.detail}`,
        duration: 0,
        top: 56,
      });
    }
    message.destroy("email-status");
    setLoading(false);
  };

  // DELETE DRAFTS
  const deleteDrafts = async () => {
    setDeleting(true);
    message.loading({
      content: "Deleting drafts in background...",
      key: "email-status",
      duration: 0,
    });
    // Drafts already exist. Send those. Use special endpoint

    const res = await mmobDelete(
      `/receipts/${company.data.Id}/delete-drafts`,
      null,
      { data: draftResults }
    );
    if (res.data) {
      const deleted = res.data.filter((r) => typeof r === "object" && !r)
        .length;
      if (deleted && deleted !== res.data.length) {
        notification["warning"]({
          message: `${deleted} / ${res.data.length} emails deleted`,
          description:
            "Some drafts failed to delete. They may have already been sent or there was an error. Check your Gmail 'Drafts' folder to review them.",
          duration: 0,
          top: 56,
        });
      } else if (!deleted) {
        notification["error"]({
          message: `${deleted} / ${res.data.length} drafts deleted`,
          description: "There was an error deleting the drafts.",
          duration: 0,
          top: 56,
        });
      } else {
        notification["success"]({
          message: `${deleted} / ${res.data.length} drafts deleted`,
          duration: 0,
          top: 56,
        });
      }
    } else {
      console.log("Failed to delete drafts: ", res.error);
    }
    message.destroy("email-status");
    handleDraftResults(null);
    setDeleting(false);
  };

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current > moment().endOf("day");
  // }

  const handleDate = (datetime) => {
    setExcludeDate(datetime?.format());
    handleExcludeDate(datetime?.format());
  };

  // await emailReceipts();
  return (
    <div>
      <Space
        direction="vertical"
        align="center"
        split
        style={{ height: "100%", width: "100%", padding: 128, marginTop: 0 }}
      >
        <Text>Exclude donors who have been sent an email on or before:</Text>
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          // disabledDate={disabledDate}
          onChange={handleDate}
          showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
          allowClear
          style={{ marginTop: 8 }}
        />
        <br />
        <Alert
          style={{ marginBottom: 8, width: "100%", position: "inherit" }}
          message="Tip: Perform a dry-run (optional)"
          description="You have the option of performing a dry-run before sending emails to your recipients. When you perform a dry-run, draft emails are created in your Gmail Draft Folder, allowing you to make one last check before sending emails to your recipients."
          type="info"
          closable
          // closeText="Continue"
          showIcon
        />
        <Space direction="vertical" align="center" size="middle">
          <Button onClick={sendDrafts} loading={loading}>
            Perform Dry-run
          </Button>
          <Button
            disabled={!draftResults}
            loading={deleting}
            type="link"
            title="Deletes the draft emails created by dry-run."
            onClick={deleteDrafts}
          >
            Delete Drafts
          </Button>
        </Space>
      </Space>
    </div>
  );
};

export default SendReceipts;
