import React, { useState, useContext, useEffect } from "react";
import {
	Button,
	Form,
	Input,
	Space,
	Typography,
	message,
	Select,
	Dropdown,
	Divider,
} from "antd";

import TextEditor from "./TextEditor";
import { VariableSearchInput } from "./TextEditor";

import { mmobPost, mmobPut } from "../../Api/mmobapi";
import { CompanyFileContext } from "../DashboardContext";
import UploadAttachment from "./UploadAttachment";

const { Title } = Typography;
const { Option } = Select;

const layout = {
	labelCol: {
		span: 4,
	},
	wrapperCol: {
		span: 0,
	},
};
const titleLayout = {
	labelCol: {
		span: 0,
	},
	wrapperCol: {
		span: 48,
	},
};

const TitleEdit = ({ template, updateTitle }) => {
	// Editable text to change title of email template
	const [editableStr, setEditableStr] = useState("New Email Template Title");

	function onChange(str) {
		setEditableStr(str);
		updateTitle(str);
	}

	useEffect(() => {
		if (template) {
			setEditableStr(template.title);
			updateTitle(template.title);
		}
		// eslint-disable-next-line
	}, [template]);

	return (
		<Title
			className="ant-typography-edit-title"
			editable={{
				onChange: onChange,
				maxLength: 32,
				autosize: { maxRows: 1 },
				tooltip: "Edit the name of this email template",
			}}
			style={{ fontSize: "18px", margin: 0, minWidth: 350 }}
		>
			{editableStr}
		</Title>
	);
};

//
//MAIN COMPONENT

const EmailEditor = ({
	reportData,
	receiptsData,
	template,
	toggleEditor,
	onClose,
}) => {
	const [form] = Form.useForm();
	const [isSaving, setIsSaving] = useState(false);
	const [title, setTitle] = useState("New Email Template Title");
	const [templateEdit, setTemplateEdit] = useState(template || null);
	const [cursorSelection, setCursorSelection] = useState(null);
	const [subject, setSubject] = useState(template ? template.subject : "");
	// const [recipients, setRecipients] = useState(
	//   template ? template.recipients : []
	// );
	let company = useContext(CompanyFileContext);

	// const startDate = reportData.Dates[0].format("D MMMM YYYY");
	// const endDate = reportData.Dates[1].format("D MMMM YYYY");
	// const recipients = [];
	const columns = Object.keys(receiptsData.donors[0]);
	let options = columns.map((item) => (
		<Option key={item} value={item}>
			{item}
		</Option>
	));

	function updateTitle(title) {
		setTitle(title);
	}

	function updateContent(body) {
		if (templateEdit) {
			setTemplateEdit({ ...templateEdit, content: body });

			form.setFieldsValue({ content: body });
		} else {
			setTemplateEdit({ ...templateEdit, content: body });
		}
	}

	const handleInsertSubject = React.useCallback(
		(value) => {
			const prev = form.getFieldValue("subject") || "";
			const newSubject = [
				prev.slice(0, cursorSelection),
				value,
				prev.slice(cursorSelection),
			].join("");

			setSubject(newSubject);
			setTemplateEdit({ ...templateEdit, subject: newSubject });
			setCursorSelection(cursorSelection + newSubject.length);
		},
		[form, templateEdit, cursorSelection]
	);

	function updateAttachments(fileList) {
		form.setFieldsValue("attachments", fileList);
		setTemplateEdit({
			...templateEdit,
			attachments: fileList,
		});
		console.log("FILELIST: ", fileList);
	}

	const handleBlur = () => {
		setTemplateEdit({ ...templateEdit, ...form.getFieldsValue() });
	};

	const handleSelect = React.useCallback((e) => {
		setCursorSelection(e.target.selectionStart);
	}, []);

	const handleSubjectChange = React.useCallback(
		(e) => {
			setSubject(e.target.value);
			form.setFieldsValue({ subject: e.target.value });
		},
		[form]
	);

	const onSave = async () => {
		// Save email template to DB
		setIsSaving(true);

		const data = {
			title: title,
			subject: form.getFieldValue("subject"),
			content: form.getFieldValue("content"),
			attachments: form
				.getFieldValue("attachments")
				.map((file) => JSON.stringify(file)),
			recipients: form.getFieldValue("recipients"),
		};

		let res;

		if (!templateEdit.guid) {
			res = await mmobPost(`/email-templates/${company.data.Id}`, data);
		} else {
			res = await mmobPut(
				`/email-templates/${company.data.Id}/${templateEdit.guid}`,
				data
			);
		}
		if (res.data) {
			message.success("New Email template created.");
			setTemplateEdit(res.data);
		}
		if (res.status === 204) {
			message.success("Email template updated.");
		}
		if (res.error) {
			if (res.status === 409) {
				message.error(
					"Failed saving template: Duplicate template name. Title must be unique."
				);
			} else {
				message.error(`Failed saving email template. ${res.error}`);
			}
		}
		// Finished saving.
		setTimeout(() => setIsSaving(false), 1000);
	};

	useEffect(() => {
		if (templateEdit) {
			form.setFieldsValue({
				...templateEdit,
			});
		} else {
			form.setFieldsValue({
				title: title,
				recipients: [],
				subject: "",
				content: "",
				attachments: [],
			});
		}
		// eslint-disable-next-line
	}, [templateEdit]);

	// Deselect template when leaving editor
	useEffect(() => {
		return () => {
			onClose(null);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Form
			form={form}
			layout="vertical"
			name="email-template-editor"
			onFinish={onSave}
			onBlur={handleBlur}
		>
			<Space
				direction="horizontal"
				style={{
					display: "flex",
					flex: "wrap",
					justifyContent: "space-between",
				}}
			>
				<Form.Item
					{...titleLayout}
					style={{
						marginLeft: 32,
						marginTop: 16,
						maxWidth: 600,
					}}
					name="title"
				>
					<TitleEdit template={template} updateTitle={updateTitle} />
				</Form.Item>

				<Form.Item>
					<Space
						style={{
							display: "flex",
							justifyContent: "right",
							padding: "32px 32px 16px 0",
						}}
					>
						<Button
							htmlType="submit"
							type="primary"
							loading={isSaving}
						>
							Save Template
						</Button>
						<Button onClick={toggleEditor} type="primary">
							Close Editor
						</Button>
					</Space>
				</Form.Item>
			</Space>
			<Form.Item
				{...layout}
				style={{ padding: "0px 32px 0 32px" }}
				name="recipients"
				label="Recipient(s)"
				rules={[
					{
						required: true,
						type: "array",
						message: "Please input at least one recipient",
					},
				]}
			>
				<Select
					// form={form}
					placeholder="e.g. foo@example.com, bar@example.com"
					mode="tags"
					tokenSeparators={[",", ";", " "]}
					getPopupContainer={(triggerNode) =>
						triggerNode.parentElement
					}
					value={form.getFieldValue("recipients")}
				>
					{options}
				</Select>
			</Form.Item>
			<Form.Item
				{...layout}
				style={{ padding: "0 32px 0 32px" }}
				name="subject"
				label="Subject"
			>
				<Dropdown
					overlay={
						<VariableSearchInput
							handleInsert={handleInsertSubject}
							reportData={reportData}
							receiptsData={receiptsData}
						/>
					}
					trigger={["contextMenu"]}
					// getPopupContainer={(triggerNode) => triggerNode.parentElement}
				>
					<Input
						value={subject}
						onSelect={handleSelect}
						defaultValue={templateEdit ? templateEdit.subject : ""}
						onChange={handleSubjectChange}
						placeholder="e.g. Monthly Report..."
					/>
				</Dropdown>
			</Form.Item>
			<Form.Item name="content">
				<TextEditor
					reportData={reportData}
					receiptsData={receiptsData}
					template={templateEdit}
					handleSave={onSave}
					updateContent={updateContent}
				/>
			</Form.Item>
			<Divider style={{ padding: "0 0px" }} />
			<Form.Item style={{ alignItems: "end", padding: "0 16px" }}>
				<UploadAttachment
					defaultFileListStr={
						templateEdit ? templateEdit.attachments : []
					}
					updateAttachments={updateAttachments}
					onSave={onSave}
				/>
			</Form.Item>
		</Form>
	);
};

export default EmailEditor;
