import React, { useEffect, useState } from "react";
import { Button, Typography, Space, Spin } from "antd";
import { GoogleOutlined } from "@ant-design/icons";

import { mmobGet, mmobDelete } from "../../Api/mmobapi";

const { Paragraph, Title } = Typography;

const SCOPES = [
  "https://mail.google.com/",
  "https://mail.google.com/mail/feed/atom",
  "https://mail.google.com",
  "https://mail.google.com/mail/feed/atom/",
  "https://mail.google.com/mail",
  "https://mail.google.com/mail/",
  "http://mail.google.com/",
  "http://mail.google.com ",
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/gmail.compose",
  "https://www.googleapis.com/auth/gmail.addons.current.action.compose",
];

const ConnectGmailDialog = ({ googleUser, userCallback }) => {
  const [scope, setScope] = useState(["openid", "email", ...SCOPES]);
  const [authURL, setAuthURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  const openInNewTab = (url) => {
    setLoading(true);
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const disconnectGoogleUser = async () => {
    setLoading(true);
    // Deletes google tokens from server
    await mmobDelete("/auth/google/current-user/signout");
    await getAuthURL();
    userCallback(null);
  };

  const getAuthURL = async () => {
    const params = { scope: scope.join(" ") };
    let res = await mmobGet("/auth/google", params);
    setAuthURL(res.data);
  };

  const getCurrentGoogleUser = async () => {
    setLoading(true);
    setLoadingRefresh(true);
    // await getAuthURL();
    // API GET: to auth/google/current-user
    try {
      let res = await mmobGet("/auth/google/current-user");
      // console.log(res);
      if (res.data) {
        userCallback(res.data.emailAddress);
        setLoading(false);
        setLoadingRefresh(false);
      } else {
        setTimeout(() => {
          setLoading(false);
          setLoadingRefresh(false);
        }, 1500);
      }
    } catch (e) {
      // suppress error
    }
  };

  useEffect(() => {
    getAuthURL();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!googleUser) {
      userCallback(googleUser);
      getCurrentGoogleUser();
    }
    // eslint-disable-next-line
  }, [googleUser]);

  return (
    <Space
      direction="vertical"
      style={{ justifyContent: "center", paddingBottom: 32 }}
    >
      <Spin spinning={loading} size="large">
        {googleUser ? (
          <Space direction="vertical" style={{ justifyContent: "center" }}>
            <Paragraph style={{ display: "flex", justifyContent: "center" }}>
              Connected to:
            </Paragraph>
            <Title
              style={{ display: "flex", justifyContent: "center" }}
              level={3}
            >
              {googleUser}
            </Title>
            <Paragraph>
              Not the right account?{" "}
              <Button
                style={{ paddingRight: 0 }}
                type="link"
                onClick={() => {
                  disconnectGoogleUser();
                  openInNewTab(authURL);
                }}
              >
                Connect to another account.
              </Button>
            </Paragraph>
          </Space>
        ) : (
          <Space
            direction="vertical"
            style={{
              paddingBottom: 16,
              justifyContent: "center",
            }}
          >
            <Paragraph>No Gmail accounts connected.</Paragraph>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                icon={<GoogleOutlined />}
                type="default"
                onClick={() => {
                  openInNewTab(authURL);
                  setTimeout(() => getCurrentGoogleUser(), 5000);
                }}
              >
                Login to Gmail
              </Button>
            </div>
          </Space>
        )}
      </Spin>
      {!googleUser && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={loadingRefresh}
            // hidden={!loading}
            onClick={() => getCurrentGoogleUser()}
            type="default"
          >
            Refresh
          </Button>
        </div>
      )}
    </Space>
  );
};

export default ConnectGmailDialog;
