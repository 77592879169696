import { Result, Button } from "antd";

const HTTPExceptionHandler = (error) => {
  try {
    if (error.response.status === 401) {
      console.log("Session has expired or unauthorized. Please login.");
      window.location.href = "/dashboard/company-files";
    } else if (error.response.data === 500) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button onClick={() => (window.location.href = "/")} type="primary">
              Back Home
            </Button>
          }
        />
      );
    }
  } catch (othererror) {
    // Do nothing if data received is empty.
  }
};

export default HTTPExceptionHandler;
