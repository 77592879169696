import React from "react";
import { List, Spin } from "antd";
import CustomReportCard from "./CustomReportCard";

const CustomReportCardList = ({ data, onSelect }) => {
  let isLoading = true;

  if (!data) {
    isLoading = true;
    data = [];
  } else {
    isLoading = false;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <List
          grid={{
            gutter: 32,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 5,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <CustomReportCard item={item} onSelect={onSelect} />
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};

export default CustomReportCardList;
