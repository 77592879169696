import React, { useContext, useEffect } from "react";
import { v4 as uuid4 } from "uuid";
import { List, Spin, Alert } from "antd";
import withFetch from "../../withFetch";
import CompanyCard from "./CompanyCard";
import { AlertAreaContext } from "../DashboardContext";

const CompanyCardList = (props) => {
  const alertContext = useContext(AlertAreaContext);

  let data = props.dataSource;
  let isLoading = true;

  if (!data) {
    isLoading = true;
    data = [];
  } else {
    isLoading = false;
  }

  useEffect(() => {
    alertContext.addAlert([
      <Alert
        style={{ marginBottom: "16px" }}
        message="Select a company file to continue."
        type="info"
        closable
        showIcon
        key={uuid4()}
      />,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          style={{ paddingTop: "16px" }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <CompanyCard item={item} />
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};

const CompanyFilesList = withFetch(CompanyCardList);

export default CompanyFilesList;
