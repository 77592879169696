import React, { useState, useContext } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// import Cookies from "js-cookie";
import { mmobPost, mmobDelete } from "../../Api/mmobapi";
import { CompanyFileContext } from "../DashboardContext";
// import Axios from "axios";

const UploadAttachment = ({
	defaultFileListStr,
	updateAttachments,
	onSave,
}) => {
	const [fileList, setFileList] = useState(defaultFileListStr);
	const [progress, setProgress] = useState(0);
	const company = useContext(CompanyFileContext);

	const headers = {
		"content-type": "multipart/form-data",
	};

	const uploadFile = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;

		const fmData = new FormData();
		const config = {
			headers: headers,
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 500);
				}
				onProgress({
					percent: ((event.loaded / event.total) * 100).toFixed(0),
				});
			},
		};
		fmData.append("upload", file);

		const res = await mmobPost(
			`/uploads/${company.data.Id}/${file.uid}`,
			fmData,
			null,
			config
		);

		if (res.data) {
			onSuccess("Ok");
			message.success("File uploaded");
		}
		if (res.error) {
			onError(res.error);
			message.error(`Failed file upload: ${res.error} `);
		}
	};

	const handleOnChange = ({ file, fileList: files }) => {
		console.log(file.status);
		if (file.status === "done") {
			console.log("PROGRESS: ", progress);
			setTimeout(() => setProgress(0), 500);
			updateAttachments(files);
			setFileList(files);
		}

		if (file.status === "uploading") {
			updateAttachments(files);
			setFileList(files);
		}

		if (file.status === "removed") {
			updateAttachments(files);
			setFileList(files);
			onSave();
		}
	};

	const handleRemove = async (file) => {
		const res = await mmobDelete(
			`/uploads/${company.data.Id}/${file.uid}`,
			null,
			{ data: file }
		);

		if (res.data) {
			message.success("Attachment deleted");
			console.log("1: ", file);
			file.status = "done";
			console.log("2: ", file);
			const idx = fileList.map((f) => f.uid).indexOf(file.uid);
			const newList = fileList.splice(idx + 1, 1);
			console.log("NEWLIST: ", newList);
			setFileList(newList);
			updateAttachments(newList);
			await onSave();
		}
		if (res.error) {
			if (res.error.response.status === 404) {
				const idx = fileList.map((f) => f.uid).indexOf(file.uid);
				console.log("INDEX: ", idx);
				const newList = fileList.splice(idx + 1, 1);
				console.log("NEWLIST: ", newList);
				setFileList(newList);
				updateAttachments(newList);
				await onSave();
			} else {
				message.error(`Failed to delete attachment: ${res.error} `);
			}
		}
	};

	return (
		<div
			style={{
				margin: 8,
				width: 300,
			}}
		>
			<Upload
				customRequest={uploadFile}
				onChange={handleOnChange}
				fileList={fileList}
				defaultFileList={defaultFileListStr}
				onRemove={handleRemove}
				progress={progress}
			>
				<Button icon={<UploadOutlined />}>Upload Attachment</Button>
			</Upload>
		</div>
	);
};

export default UploadAttachment;
