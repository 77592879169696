import React from "react";
import { PageHeader, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

export default function LandingPage() {
  return (
    <>
      <PageHeader>
        <Title>Welcome to MMOB</Title>
        <Paragraph>The MYOB AccountRight Plugin</Paragraph>
        <h3>
          <Link to="/dashboard/company-files">Get Started!</Link>
        </h3>
      </PageHeader>
    </>
  );
}
